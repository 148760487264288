<script>
    import InlineSvg from 'vue-inline-svg'

    export default {
        name: 'CallToAction',
        props: {
            'cta': Object
        },
        render(createElement) {
            const cta = this.cta
            const linkName = cta[`${ this.cta.type }_link`].post_name

            return createElement('router-link', {
                class: 'c-btn c-btn--has-icon c-btn--large',
                props: {
                    to: `/${ cta.type }/${ linkName }`
                }
            },
                [
                    cta.text,
                    createElement('inline-svg', {
                        props: {
                            src: require(`@/assets/icons/${ cta.icon }.svg`)
                        }
                    })
                ])
        },

        components: {
            InlineSvg
        }
    }
</script>

<style scoped lang="scss" src=""></style>