import api from "../../api";
import * as types from "../mutation-types";

// initial state
const state = {
  meta: {
    tags: [],
    related: [],
    intro: []
  },
  all: [],
  allLoaded: false,
  loadMore: 0,
  current: null,
  loaded: false,
};

// getters
const getters = {
  allPosts: state => state.all,
  allPostsLoaded: state => state.allLoaded,
  currentPost: state => state.current,
  postLoaded: state => state.loaded,
  moreLoaded: state => state.loadMore
};

// actions
const actions = {
  getAllPosts({ commit }) {
    api.getPosts(posts => {
      commit(types.STORE_FETCHED_POSTS, { posts });
      commit(types.POSTS_LOADED, true);
      commit(types.INCREMENT_LOADING_PROGRESS);
    });
  },

  getPost({ commit }, {id, oid}) {
    api.getPost(id, oid, (post) => {
      commit(types.STORE_FETCHED_POST, { post });
      commit(types.STORE_UPDATED_USER_HISTORY, {post})
      commit(types.POST_LOADED, true);
    })
  },

  getPostPreview({ commit }, id) {
    api.getPostPreview(id, (post) => {
      commit(types.STORE_FETCHED_POST, { post });
      commit(types.POST_LOADED, true);
    })
  },

  loadMorePosts({ commit }) {
    commit(types.POSTS_LOAD_MORE, 1)
  }
};

// mutations
const mutations = {
  [types.STORE_FETCHED_POSTS](state, { posts }) {
    state.all = posts;
  },

  [types.STORE_FETCHED_POST](state, { post }) {
    state.current = post;
  },

  [types.POSTS_LOADED](state, val) {
    state.allLoaded = val;
  },

  [types.POST_LOADED](state, val) {
    state.loaded = val;
  },

  [types.POSTS_LOAD_MORE](state, val) {
    state.loadMore += val
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
