<template>
    <div class="c-menu__group">
        <div class="c-menu__inner c-menu__inner--main">
            <div class="o-container o-container--full">
                <div class="o-layout o-layout--lg o-layout--gutter o-layout--4">
                    <div class="c-menu__linklist c-txt--center" v-for="(list, i) in data.tag" :key="i">
                        <p class="c-menu__linklist-title c-txt--upper u-color--light-blue">{{ list.headline }}</p>
                        <router-link class="c-link c-menu__link" v-for="(link, j) in list.items" :key="j"
                                     :to="linkPath(link)"
                                     @click.native="closeMenu">{{ link.type === 'tag' ? '#' : null }}{{ linkTitle(link) }}
                        </router-link>
                    </div>

                    <div class="c-menu__linklist c-txt--center">
                        <p class="c-menu__linklist-title c-txt--upper u-color--light-blue">Meistgeklickt</p>
                        <router-link class="c-link c-menu__link" v-for="(link, k) in data.popular_tags" :key="k"
                                     :to="{ path: `/archive/${link.post_name}`, params: { archiveId : link.ID } }"
                                     @click.native="closeMenu">
                            #{{ link.post_title | limitLength(0, 20) }}
                        </router-link>
                    </div>

                    <div v-if="data.cta.length > 0" class="c-menu__linklist c-menu__linklist--cta">
                        <call-to-action v-for="(cta, ctaI) in data.cta" class="c-menu__cta" :cta="cta" :key="ctaI" @click.native="closeMenu"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-menu__footer" v-if="data.page">
            <div class="o-container">
                <div class="c-menu__inner c-menu__inner--footer">
                    <router-link v-for="(link, k) in data.page" :to="`/page/${ link.post_name }`"
                                 class="c-menu__link c-menu__link--footer" :key="k" @click.native="closeMenu">{{
                        link.post_title }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import CallToAction from "./menu/CallToAction";

    export default {
        name: 'MainMenuContent',
        props: {
            data: Object
        },

        methods: {
            ...mapActions(['openMenu']),

            closeMenu() {
                this.openMenu(false)
            },

            linkPath(link) {
                let path = link[link.type].post_name;
                let id = link[link.type].ID

                switch (link.type) {
                    case 'page':
                        return `/page/${path}`;

                    case 'post':
                        return `/post/${path}`;

                    case 'tag':
                        return { path: `/archive/${path}`, archiveId: id };
                }
            },

            linkTitle(link) {
                return link.type === 'post' ? link.title : link[link.type].post_title
            }
        },

        components: {
            CallToAction
        }
    }
</script>
