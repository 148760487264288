import api from "../../api";
import * as types from "../mutation-types";

// initial state
const state = {
  all: [],
  loaded: false,
  allPagesLoaded: false,
  page: null
};

// getters
const getters = {
  allPages: state => state.all,
  allPagesLoaded: state => state.allPagesLoaded,
  page: state => state.page,
  somePages: state => limit => {
    if (state.all.length < 1) {
      return false;
    }
    let all = [...state.all];
    return all.splice(0, Math.min(limit, state.all.length));
  }
};

// actions
const actions = {
  getAllPages({ commit }) {
    api.getPages(pages => {
      commit(types.STORE_FETCHED_PAGES, { pages });
      commit(types.PAGES_LOADED, true);
      commit(types.INCREMENT_LOADING_PROGRESS);
    });
  },

  getPage({ commit }, id) {
    api.getPage(id, (page) => {
      commit(types.STORE_FETCHED_PAGE, { page });
      commit(types.PAGE_LOADED, true);
      commit(types.INCREMENT_LOADING_PROGRESS);
    })
  },
};

// mutations
const mutations = {
  [types.STORE_FETCHED_PAGES](state, { pages }) {
    state.all = pages;
  },

  [types.PAGES_LOADED](state, val) {
    state.allPagesLoaded = val;
  },

  [types.PAGE_LOADED](state, val) {
    state.loaded = val;
  },

  [types.STORE_FETCHED_PAGE](state, { page }) {
    state.page = page
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
