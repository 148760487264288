<template>
    <div>
        <div class="c-tease c-tease--compact c-tease--compact-loading" v-for="i in articleCount" :key="i">
        <div class="c-tease__content c-tease__content--search">
            <div class="c-tease__title c-tease__title--search"></div>
            <div class="c-tease__subtitle c-tease__subtitle--search"></div>
            <div class="c-meta c-tease__date c-tease__date--search"></div>
        </div>
            <div class="c-tease__img c-tease__img--search"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',

        data () {
            return {
                articleCount: 5
            }
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_tease.scss"></style>