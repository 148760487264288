<template>
    <div class="c-acc" :class="{ 'c-acc--no-articles' : !accountData[currentTab].data }">
        <nav v-if="accountData" class="c-acc__nav">
            <ul class="c-acc__nav-list o-container o-container--full">
                <li v-for="(item, itemIndex) in accountData"
                    class="c-acc__nav-item"
                    :class="{ 'c-acc__nav-item--current' : currentTab === itemIndex }"
                    @click="currentTab = itemIndex"
                    :key="itemIndex">
                    <button class="c-acc__trigger">{{ item.title }}</button>
                </li>
            </ul>
        </nav>
        <div v-else>loading</div>
        <div v-if="accountData[currentTab].data" class="c-acc__articles o-container o-container--full" :class="{ 'c-acc__articles--all' : showAllArticles }">
            <account-post-teaser
                    v-for="(article, articleIndex) in accountData[currentTab].data"
                    :postData="article"
                    :postType="currentTab"
                    :singleRouteName="accountData[currentTab].singleRouteName"
                    :key="articleIndex">
            </account-post-teaser>
        </div>
        <div v-else class="c-acc__no-articles">
            <p>Keine Artikel vorhanden</p>
        </div>
        <button
                v-if="accountData[currentTab].data"
                class="c-btn c-btn--inline c-btn--load-more c-acc__load-more"
                :tabindex="$mq === 'small' || $mq === 'medium' ? -1 : 0 "
                @click="showAllArticles = !showAllArticles"
        >
            <span>Alle Artikel</span>
            <inline-svg :src="require(`@/assets/icons/arrow/right.svg`)"/>
        </button>
    </div>
</template>

<script>
    import InlineSvg from 'vue-inline-svg'
    import { mapGetters, mapActions } from 'vuex'
    import AccountPostTeaser from './account/AccountPostTeaser';

    export default {
        name: 'Account',

        data () {
            return {
                currentTab: 'bookmarks',
                showAllArticles: false
            }
        },

        watch: {
            'showAllArticles': function(val) {
                this.$emit('allarticles', val )
            }
        },

        methods: {
            ...mapActions(['getUserPostData']),
        },

        computed: {
            ...mapGetters({
                oidcUser: 'oidcUser',
                userHistory: 'userHistory',
                userBookmarks: 'userBookmarks',
                userLikes: 'userLikes'
            }),

            accountData () {
                return { 'bookmarks': this.userBookmarks, 'likes': this.userLikes, 'history': this.userHistory }
            }
        },

        components: {
            AccountPostTeaser,
            InlineSvg
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_acc.scss"></style>