<script>
    import { mapActions, mapGetters } from 'vuex'
    import InlineSvg from 'vue-inline-svg'

    export default {
        name: 'ActionLink',

        props: {
            data: Object,
            id: Number
        },

        render(h) {
            const data = this.data;

            return h(data.action === 'link' ? 'a' : 'button', {
                    class: [
                        { 'is-active': data.routeName === 'bookmark' && this.bookmarkedPost },
                        { 'is-active': data.routeName === 'like' && this.likedPost },
                    ],
                    domProps: {
                        href: data.action === 'link' ? data.link : null
                    },
                    on: {
                        click: () => {
                            if (data.routeName === 'bookmark') {
                                this.toggleUserBookmark({'oid': this.userOid, 'id': this.id})
                            }
                            if (data.routeName === 'like') {
                                this.toggleUserLike({'oid': this.userOid, 'id': this.id})
                            }
                        }
                    },
                }, [
                    h('inline-svg', {
                        props: {
                            src: require(`@/assets/icons/${ data.icon }.svg`)
                        }
                    })
                ]
            )
        },

        methods: {
            ...mapActions({
                updatePostOnAccount: 'updatePostOnAccount',
                toggleUserBookmark: 'toggleUserBookmark',
                toggleUserLike: 'toggleUserLike'
            })
        },

        computed: {
          ...mapGetters({
							userOid: 'userOid',
              userBookmarks: 'userBookmarks',
              userLikes: 'userLikes'
          }),

            bookmarkedPost() {
                if (this.userBookmarks.data === false) return false;
              const postArrByID = this.userBookmarks.data.map(post => post.ID)
              return postArrByID.includes(this.id)
            },

            likedPost() {
                if (this.userLikes.data === false) return false;
                const postArrByID = this.userLikes.data.map(post => post.ID)
                return postArrByID.includes(this.id)
            }
        },

        components: {
            InlineSvg
        }
    }
</script>
