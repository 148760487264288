import api from "../../api";
import * as types from '../mutation-types'

// initial state
const state = {
	restriction_msg: null,
	uid: '',
	oid: '',
	likes: [],
	bookmarks: [],
	history: []
}

// getters
const getters = {
	userOid: state => state.oid,
	userUid: state => state.uid,
	userLikes: state => state.likes,
	userBookmarks: state => state.bookmarks,
	userHistory: state => state.history,
	userRestrictionMsg: state => state.restriction_msg
}

// actions
const actions = {
	getUserRestrictionMessage: ({ commit }) => {
		api.getSiteMessage(message => {
			commit(types.STORE_FETCHED_USER_RESTRICTION_MSG, message)
		})
	},

	setUserID: ({commit}, id) => commit(types.STORE_FETCHED_USER_ID, id),

	getUserPostData({commit}, {oid, uid}) {
		api.getAccPosts(oid, user => {
			commit(types.STORE_FETCHED_USER_DATA, {user, uid})
			commit(types.INCREMENT_LOADING_PROGRESS);
		})
	},

	toggleUserBookmark({commit}, {oid, id}) {
		api.toggleUserBookmark(oid, id, bookmarks => {
			commit(types.STORE_UPDATED_USER_BOOKMARKS, {bookmarks})
		})
	},

	toggleUserLike({commit}, {oid, id}) {
		api.toggleUserLike(oid, id, likes => {
			commit(types.STORE_UPDATED_USER_LIKES, {likes})
		})
	},

	updateUserHistory({commit}, {oid, id}) {
		api.updateUserHistory(oid, id, history => {
			commit(types.STORE_UPDATED_USER_HISTORY, {history})
		})
	},

	removeUserHistoryItem({commit}, {oid, row}) {
		api.removeUserHistoryItem(oid, row, history => {
			commit(types.STORE_UPDATED_USER_HISTORY, {history})
		})

	}
}

// mutations
const mutations = {
	[types.STORE_FETCHED_USER_DATA](state, {user, uid}) {
		const {
			likes,
			bookmarks,
			history
		} = user

		state.likes = likes
		state.bookmarks = bookmarks
		state.history = history
		state.uid = uid
	},

	[types.STORE_FETCHED_USER_ID](state, id) {
		state.oid = id
	},

	[types.STORE_UPDATED_USER_BOOKMARKS](state, {bookmarks}) {
		state.bookmarks = bookmarks
	},

	[types.STORE_UPDATED_USER_LIKES](state, {likes}) {
		state.likes = likes
	},

	[types.STORE_UPDATED_USER_HISTORY](state, {history}) {
		state.history = history
	},

	[types.STORE_FETCHED_USER_RESTRICTION_MSG](state, data) {
		state.restriction_msg = data.messages
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
