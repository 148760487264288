export const STORE_CURRENT_MENU = 'STORE_CURRENT_MENU'
export const STORE_OPEN_MENU = 'STORE_OPEN_MENU'
export const STORE_OPEN_OVERLAY = 'STORE_OPEN_OVERLAY'
export const STORE_FETCHED_MENU = 'STORE_FETCHED_MENU'
export const MENU_LOADED = 'MENU_LOADED'

export const STORE_FETCHED_PAGES = 'STORE_FETCHED_PAGES'
export const PAGES_LOADED = 'PAGES_LOADED'

export const STORE_FETCHED_PAGE = 'STORE_FETCHED_PAGE'
export const PAGE_LOADED = 'PAGE_LOADED'

export const STORE_FETCHED_POSTS = 'STORE_FETCHED_POSTS'
export const POSTS_LOADED = 'POSTS_LOADED'
export const POSTS_LOAD_MORE = 'POSTS_LOAD_MORE'

export const STORE_FETCHED_POST = 'STORE_FETCHED_POST'
export const POST_LOADED = 'POST_LOADED'

export const STORE_FETCHED_USER_DATA = 'STORE_FETCHED_USER'
export const STORE_UPDATED_USER_BOOKMARKS = 'STORE_UPDATED_USER_BOOKMARKS'
export const STORE_UPDATED_USER_LIKES = 'STORE_UPDATED_USER_LIKES'
export const STORE_UPDATED_USER_HISTORY = 'STORE_UPDATED_USER_HISTORY'
export const STORE_FETCHED_USER_ID = 'STORE_FETCHED_USER_ID'
export const STORE_FETCHED_USER_RESTRICTION_MSG = 'STORE_FETCHED_USER_RESTRICTION_MSG'

export const INCREMENT_LOADING_PROGRESS = 'INCREMENT_LOADING_PROGRESS'
export const RESET_LOADING_PROGRESS = 'RESET_LOADING_PROGRESS'

export const INCREMENT_FEEDBACK_STEP = 'INCREMENT_FEEDBACK_STEP'
export const DECREMENT_FEEDBACK_STEP = 'DECREMENT_FEEDBACK_STEP'
export const UPDATE_FEEDBACK_STEP = 'UPDATE_FEEDBACK_STEP'
