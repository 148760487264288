<template>
    <div class="c-search">
        <p class="o-container c-search__notice" v-if="notice">{{ notice }}</p>

        <div class="o-container c-search__input-group">
            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'c-search__input-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="35.384" cy="21.577" r="19.577"/><path d="M16.344 40.536L2 54.88"/></svg>

            <input required minlength="3" type="text"
                   class="c-search__input"
                   :class="{ 'c-search__input--has-notice' : notice }"
                   @keyup.13="onSearchEnter(query)"
                   placeholder="Suche"
                   v-model="query"
                   @focus="inputActive = true"
                   @blur="populateQueryHistory"
                   ref="searchInput">

            <button class="c-btn c-search__clear" v-if="query.length > 0" @click="resetSearch()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M46 10H12.54L-.317 25l12.855 15H46c2.207 0 4-1.793 4-4V14c0-2.207-1.793-4-4-4zM34.707 30.293l-1.414 1.414L28 26.414l-5.293 5.293-1.414-1.414L26.586 25l-5.293-5.293 1.414-1.414L28 23.586l5.293-5.293 1.414 1.414L29.414 25z"/></svg>
            </button>
        </div>

        <transition name="meta">
            <div class="c-search__meta" :class="{ 'c-search__meta--filter' : showFilter }">
                <div class="o-container">
                    <div class="c-search__filter c-filter" v-if="showFilter">
                        <ul class="c-list c-list--filter">
                            <li class="c-list__item c-list__item--filter">
                                <button
                                        class="c-btn c-filter__trigger"
                                        @click="toggleFilter('date')"
                                        :class="{ 'is-active' : filter_selected === 'date' }"
                                >
                                    <span>Datum</span>
                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.669 1l-6.5 6-6.5-6"/></svg>
                                </button>
                                <ul class="c-list c-list--nested">
                                    <li class="c-list__item c-list__item--nested">
                                        <button
                                                class="c-btn c-btn--filter"
                                                @click="sortResults('DESC')"
                                                :class="{ 'c-btn--selected' : resultsOrder === 'DESC' }"
                                                :disabled="resultsOrder === 'DESC'"
                                        >Neueste zuerst
                                        </button>
                                    </li>
                                    <li class="c-list__item c-list__item--nested">
                                        <button
                                                class="c-btn c-btn--filter"
                                                @click="sortResults('ASC')"
                                                :class="{ 'c-btn--selected' : resultsOrder === 'ASC' }"
                                                :disabled="resultsOrder === 'ASC'"
                                        >Älteste zuerst
                                        </button>
                                    </li>
                                </ul>
                            </li>
                            <li class="c-list__item c-list__item--filter"
                                v-for="(index, filterIndex) in Object.keys(filter).slice(1)" :key="filterIndex">
                                <button class="c-btn c-filter__trigger" @click="toggleFilter(index)"
                                        :class="{ 'is-active' : filter_selected === index }"><span>{{ filter[index].label }}</span><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.669 1l-6.5 6-6.5-6"/></svg></button>
                                <ul class="c-list c-list--nested">
                                    <li class="c-list__item c-list__item--nested"
                                        v-for="(item, itemIndex) in filter[index].choices" :key="itemIndex">
                                        <button class="c-btn c-btn--filter"
                                                :class="[{ 'c-btn--selected' : facet_data.facets[index].includes(item.value) }, { 'c-btn--disabled' : item.count === 0 }]"
                                                :disabled="item.count === 0" @click="populateFilter(item.value, index)">
                                            <span>{{ `${ item.label.trim() }&ensp;(${ item.count })` }}</span>
                                        </button>
                                    </li>
                                    <button class="c-btn c-btn--inline c-btn--reset-filter" :class="{ 'c-btn--disabled' : facet_data.facets[index].length === 0 }" :disabled="facet_data.facets[index].length === 0" @click="resetFilter(index)">
                                        Zurücksetzen
                                    </button>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div v-else class="c-search__last-searches">
                        <p class="c-txt--upper c-txt--bold u-color--light-blue">Letzte Suchanfragen</p>
                        <ul class="c-list">
                            <li class="c-list__item c-list__item--link c-txt--large c-txt--bold"
                                v-for="(item, index) in slicedLastQueries" :key="index" @click="updateQuery(item)">
                                <button class="c-btn c-btn--inline c-search__last-search">{{ item }}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="meta">
            <div class="o-container c-search__result">
                <loading v-if="loading"></loading>

                <div class="c-search__result-container" v-if="results.length > 0">
                    <p class="c-txt--upper c-txt--bold u-color--light-blue">
                        {{ resultsType === 'history' ? 'Zuletzt angesehene Artikel' : 'Suchergebnisse' }}</p>
                    <router-link
                            :to="`/post/${article.post_name}`"
                            v-for="article in results"
                            class="c-tease c-tease--compact"
                            :key="article.id"
                            @click.native="openMenu(false)"
                    >
                    <span class="c-tease__content c-tease__content--search">
                        <h2 class="c-tease__title c-tease__title--search">{{
                        article.post_title | limitLength(0, 40) }}</h2>
                        <span class="c-tease__subtitle c-tease__subtitle--search">{{ article.post_tagline }}</span>
                        <time class="c-meta c-tease__date c-tease__date--search" :datetime="article.post_date_default">{{
                            article.post_date_formatted }}
                        </time>
                    </span>
                        <span class="c-tease__img c-tease__img--search"
                              :style="{ backgroundImage: `url(${ article.featured_image ? article.featured_image.sizes.medium.src[0] : '' })` }"></span>
                    </router-link>
                </div>
                <p class="c-search__no-results-txt" v-else>Keine Ergebnisse</p>
            </div>
        </transition>
    </div>
</template>

<script>
    import _debounce from 'lodash.debounce'
    import axios from 'axios'
    import SETTINGS from "@/settings"
    import {mapActions} from 'vuex'

    const instance = axios.create({
      headers: {
          'apikey' : 'e7b74db8c11a17209c5a920d2d57e829',
          'secret' : '65e1f06469661f3c9c6a1cf9f6b32092'
      }
    });

    import Loading from "./Search/Loading"
    //import SearchLastQueries from "./Search/LastQueries";
    //import SearchFilter from "./Search/Filter";
    //import SearchResults from "./Search/Results";
    // TODO: CLEANUP THIS MESS

    export default {
        name: 'SearchMenuContent',

        watch: {
            'query': function (q) {
                if (q.length >= 3) {
                    this.search(q)
                }
            }
        },

        data() {
            return {
                query: '',
                results: [],
                resultsType: 'search',
                resultsOrder: 'DESC',
                inputActive: false,
                lastQueries: [],
                errors: [],
                notice: null,
                loading: false,
                showFilter: false,
                filter: null,
                filter_menu: {type: false, thema: false, date: false},
                filter_selected: null,

                facet_data: {
                    template: 's_results',
                    facets: {
                        search: '',
                        thema: [],
                        type: [],
                    },
                    query_args: {
                        post_type: 'post',
                        orderby: 'post_date',
                        order: 'ASC',
                        post_status: 'publish',
                        posts_per_page: -1,
                        paged: 1
                    },
                    settings: {
                        first_load: true
                    }
                }
            }
        },

        mounted() {
            this.lastQueries = JSON.parse(localStorage.getItem('searchqueries')) || []
            this.togglePostHistory()
        },

        beforeDestroy() {
            this.populateQueryHistory()
        },

        methods: {
            ...mapActions({
                openMenu: 'openMenu',
            }),

            populateQueryHistory() {
                if (!this.lastQueries.includes(this.query) && this.query.length > 3) {
                    this.lastQueries.unshift(this.query)
                    localStorage.setItem('searchqueries', JSON.stringify(this.lastQueries))
                }
                if (this.lastQueries.length >= 3) {
                    let slicedArr = this.lastQueries.slice(0, 3)
                    localStorage.setItem('searchqueries', JSON.stringify(slicedArr))
                }
            },

            togglePostHistory() {
                this.results = JSON.parse(localStorage.getItem('posthistory')) || []
                this.resultsType = 'history'
            },

            sortResults(order) {
                this.resultsOrder = order
                this.results.reverse()
                this.filter_selected = null
            },

            toggleFilter(type) {
                if (this.filter_selected === type) {
                    this.filter_selected = null
                } else {
                    this.filter_selected = type
                }
            },

            resetFilter(type, thema) {
                if (type) this.facet_data.facets[type] = []
                if (thema) this.facet_data.facets[thema] = []

                this.filterResults()
            },

            resetSearch() {
                this.$refs.searchInput.focus()
                this.query = '';
                this.results = []
                this.facet_data = {
                    facets: {
                        search: '',
                        type: [],
                        thema: []
                    }
                }
                this.showFilter = false
                this.togglePostHistory()
            },

            onSearchEnter() {
                if (this.query.length < 3) {
                    this.notice = 'Bitte geben Sie mindestens drei Zeichen ein'
                } else {
                    this.$refs.searchInput.blur()
                    this.notice = null
                }
            },

            search: _debounce(function (q) {
                let formData = new FormData;

                this.loading = true
                this.facet_data.facets.search = q

                formData.append('data', JSON.stringify(this.facet_data))

                if (this.facet_data.facets.type || this.facet_data.facets.thema) {
                    this.resetFilter('type', 'thema')
                } else {
                    instance.post(`${SETTINGS.API_FILTER_PATH}fetch`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(r => {
                            this.results = r.data.results
                            this.filter = r.data.facets
                            this.loading = false
                            this.showFilter = r.data.results.length > 0
                            this.resultsType = 'search'
                        })

                        .catch(e => {
                            this.errors.push(e.message)
                        })
                }
            }, 300),

            populateFilter(filter, filter_type) {
                const currentFilter = this.facet_data.facets[filter_type]
                const filterItemIndex = currentFilter.indexOf(filter)

                if (currentFilter.includes(filter)) {
                    if (filterItemIndex > -1) currentFilter.splice(filterItemIndex, 1);
                } else {
                    currentFilter.push(filter)
                }

                this.filterResults()
            },

            filterResults() {
                let formData = new FormData;

                this.loading = true;

                formData.set('data', JSON.stringify(this.facet_data))

                instance.post(`${SETTINGS.API_FILTER_PATH}fetch`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(r => {
                        this.results = r.data.results
                        this.filter = r.data.facets
                        this.loading = false
                        this.showFilter = r.data.results.length > 0
                        this.resultsType = 'search'
                    })

                    .catch(e => {
                        this.errors.push(e.message)
                    })

            },

            updateQuery(q) {
                this.query = q
                this.notice = null
                this.lastQueries.sort((a, b) => {
                    if (a === q) {
                        return -1
                    }
                    if (b === q) {
                        return 1
                    }
                    return 0
                })
            }
        },

        computed: {
            slicedLastQueries() {
                return this.lastQueries.slice(0, 3)
            }
        },

        components: {
            Loading
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_search.scss"></style>
<style scoped lang="scss" src="@/styles/components/_tease.scss"></style>
<style scoped lang="scss" src="@/styles/components/_list.scss"></style>
<style scoped lang="scss" src="@/styles/components/_filter.scss"></style>