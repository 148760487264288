import api from "../../api";
import * as types from "../mutation-types";

// initial state
const state = {
    menu: null,
    open: false,
    overlayOpen: false,
    current: null,
    loaded: false
};

// getters
const getters = {
    menu: state => state.menu,
    menuIsOpen: state => state.open,
    overlayIsOpen: state => state.overlayOpen,
    currentMenu: state => state.current,
    menuLoaded: state => state.loaded
};

// actions
const actions = {
    getPageMenu({ commit }) {
        api.getMenu(menu => {
            commit(types.STORE_FETCHED_MENU, { menu });
            commit(types.MENU_LOADED, true);
            commit(types.INCREMENT_LOADING_PROGRESS);
        })
    },

    setCurrentMenu({ commit }, type) {
        commit(types.STORE_CURRENT_MENU, type )
    },

    openMenu({ commit }, status) {
        commit(types.STORE_OPEN_MENU, status )
    },

    openOverlay({ commit }, status) {
        commit(types.STORE_OPEN_OVERLAY, status )
    }
};

// mutations
const mutations = {
    [types.STORE_FETCHED_MENU](state, { menu }) {
        state.menu = menu;
    },

    [types.MENU_LOADED](state, val) {
        state.loaded = val;
    },

    [types.STORE_CURRENT_MENU](state, type) {
        state.current = type;
    },

    [types.STORE_OPEN_MENU](state, status) {
        state.open = status;
    },

    [types.STORE_OPEN_OVERLAY](state, status) {
        state.overlayOpen = status;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
