<template>
    <transition name="menu">
        <div class="c-menu" :class="[`c-menu--${ currentMenu }`, { 'c-menu--expanded' : articlesMenuExpanded }, { 'c-menu--overlay' : overlayIsOpen }]" v-if="menuIsOpen" @keyup.27="openMenu(false)" @mouseenter="currentMenu !== 'search' ? openMenu(true) : null">
            <content-menu :data="mainMenuData" v-if="currentMenu === 'menu'"></content-menu>
            <content-search v-if="currentMenu === 'search'"></content-search>
            <content-account v-if="currentMenu === 'account'" v-on:allarticles="articlesMenuExpanded = !articlesMenuExpanded"></content-account>
        </div>
    </transition>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import contentMenu from './types/Menu'
    import contentSearch from './types/Search'
    import contentAccount from './types/Account'

    export default {
        name: 'Menu',

        data(){
            return {
                articlesMenuExpanded: false
            }
        },

        methods: {
            ...mapActions({
                setCurrentMenu: 'setCurrentMenu',
                openMenu: 'openMenu',
            }),

            closeMenu() {
                this.setCurrentMenu(this.$route.path === '/' ? 'home' : null)
                this.openMenu(false)
            }
        },

        computed: {
            ...mapGetters({
                menuIsOpen: 'menuIsOpen',
                menu: 'menu',
                currentMenu: 'currentMenu',
                overlayIsOpen: 'overlayIsOpen'
            }),

            mainMenuData() {
                const {
                    page, tag, popular_tags, cta
                } = this.menu;

                return {page, tag, popular_tags, cta}
            }
        },

        watch: {
            'menuIsOpen': function (val) {
                if (val === false) this.articlesMenuExpanded = false
            }
        },

        components: {
            contentMenu,
            contentSearch,
            contentAccount
        }
    }
</script>


<style lang="scss" scoped>
    @include bp(large) {
        .menu-enter-active, .menu-leave-active {
            @include anim($prop: transform);
        }

        .menu-enter, .menu-leave-to {
            transform: translateY(-100%);
        }

        .menu-enter-to, .menu-leave {
            transform: translateY(0);
        }
    }
</style>