import Vue from "vue";
import Router from "vue-router";
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "Home",
            component: loadView('Home')
        },
        {
            path: "/post/:postSlug",
            name: "Post",
            component: loadView('Post')
        },
        {
            path: "/preview/post/:id",
            name: "Post Preview",
            component: loadView('Post')
        },
        {
            path: "/page/:pageSlug",
            name: "Page",
            component: loadView('Page'),
        },
        {
            path: "/archive/:archiveSlug",
            name: "Archive",
            component: loadView('Archive')
        },
        { path: '/404', component: loadView('NotFound') },
        { path: '/error', component: loadView('TokenError') },
        { path: '*', redirect: '/404' },

    ],
    mode: "history",
    base: "",

    // Prevents window from scrolling back to top
    // when navigating between components/views
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
});

export default router;
