<template>
	<div class="c-tease c-tease--acc">
		<div v-if="postData" class="c-tease__link c-tease__link--acc">
			<router-link :to="`/post/${formattedPost.post_name}`" class="c-tease__img c-tease__img--acc"
				@click.native="closeMenu" v-lazy:background-image="`${ formattedPost.featured_image ? formattedPost.featured_image.sizes.full.src[0] : '' }`" :style="{ backgroundPosition: formattedPost.featured_image_position ? formattedPost.featured_image_position.replace(',', ' ') : null }">
			</router-link>
			<div class="c-tease__content c-tease__content--acc">
				<router-link
						:to="`/post/${formattedPost.post_name}`"
						class="c-tease__subtitle c-tease__subtitle--acc"
						@click.native="closeMenu"
				>{{ formattedPost.post_tagline || 'Tagline fehlt' }}
				</router-link>
				<h2 class="c-tease__title c-tease__title--acc">
					<router-link :to="`/post/${formattedPost.post_name}`" @click.native="closeMenu">{{
							formattedPost.post_title
						}}
					</router-link>
				</h2>

				<div class="c-tease__ctrl c-tease__ctrl--desktop">
					<action-link class="c-link c-link--is-icon c-link--is-icon-lg" v-for="(el, eli) in formattedPost.post_share" :data="el" :key="`action-link-${eli}`"/>
					<button class="c-link c-link--is-icon c-link--close" @click="toggleRemovePost">
						<inline-svg :src="require(`@/assets/icons/close.svg`)"/>
					</button>
				</div>
			</div>
		</div>
		<button class="c-btn c-btn--meatball c-tease__meatball" @click="editPost">
			<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 4">
				<circle cx="1.885" cy="1.885" r="1.885"/>
				<circle cx="8.167" cy="1.885" r="1.885"/>
				<circle cx="14.449" cy="1.885" r="1.885"/>
			</svg>
		</button>

		<div class="c-overlay" :class="{ 'c-overlay--open' : overlayOpen }">
			<div class="c-overlay__content c-tease c-tease--acc-display">
				<div class="c-tease__img c-tease__img--acc" v-lazy:background-image="`${ formattedPost.featured_image ? formattedPost.featured_image.sizes.full.src[0] : '' }`" :style="{ backgroundPosition: formattedPost.featured_image_position ? formattedPost.featured_image_position.replace(',', ' ') : null }">
				</div>
				<div class="c-tease__content c-tease__content--acc-display">
					<span class="c-tease__subtitle c-tease__subtitle--acc-display">{{
							formattedPost.post_tagline || 'Tagline fehlt'
						}}</span>
					<h2 class="c-tease__title c-tease__title--acc-display">{{ formattedPost.post_title }}</h2>
				</div>
				<div class="c-tease__ctrl">
					<action-link class="c-link c-link--is-icon c-link--is-icon-lg"  v-for="(el, eli) in formattedPost.post_share" :data="el"  :key="`action-link-${eli}`"/>
					<button class="c-link c-link--is-icon c-link--close" @click="toggleRemovePost">
						<inline-svg :src="require(`@/assets/icons/close.svg`)"/>
					</button>
				</div>
			</div>
			<button class="c-overlay__close" @click="closeOverlay">Abbrechen</button>
		</div>

		<div class="c-overlay c-overlay--remove" :class="{ 'c-overlay--open' : removeOverlayOpen }">
			<div class="c-overlay__content c-overlay__content--remove">
				<p class="c-overlay__notice">Möchten Sie den Artikel löschen?</p>
				<div class="c-tease__ctrl c-tease__ctrl--remove">
					<button class="c-link c-link--plain-btn" @click="toggleRemovePost">Abbrechen</button>
					<button class="c-link c-link--plain-btn" @click="removePost">Löschen</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ActionLink from '@/components/partials/ActionLink'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'AccountTeaser',
	props: {
		postData: Object,
		postType: String,
		singleRouteName: String
	},

	data() {
		return {
			overlayOpen: false,
			removeOverlayOpen: false
		}
	},

	methods: {
		...mapActions(['openMenu', 'toggleUserBookmark', 'toggleUserLike', 'removeUserHistoryItem', 'openOverlay']),

		closeMenu() {
			this.openMenu(false)
		},

		editPost() {
			this.overlayOpen = true;
			this.openOverlay(true)
		},

		toggleRemovePost() {
			this.removeOverlayOpen = !this.removeOverlayOpen
		},

		closeOverlay() {
			this.overlayOpen = false
			this.openOverlay(false);
		},

		removePost() {
			if (this.postType === 'bookmarks') {
				this.toggleUserBookmark({'oid': this.userOid, 'id': this.postData.ID})
			}

			if (this.postType === 'likes') {
				this.toggleUserLike({'oid': this.userOid, 'id': this.postData.ID})
			}

			if (this.postType === 'history') {
				this.removeUserHistoryItem({'oid': this.userOid, 'row': this.postData.row})
			}
			this.overlayOpen = false;
			this.openOverlay(false);
			this.removeOverlayOpen = false;
		},
	},

	computed: {
		formattedPost() {
			if (this.postType === 'history') return this.postData.post
			return this.postData
		},

		...mapGetters({
			userOid: 'userOid',
		})
	},

	components: {
		ActionLink,
		InlineSvg
	}
}
</script>

<style scoped lang="scss" src="@/styles/components/_btn.scss"></style>
<style scoped lang="scss" src="@/styles/components/_tease.scss"></style>
<style scoped lang="scss" src="@/styles/components/_overlay.scss"></style>
