export default {
	// How many different dispatched actions determine loading progress
	// This is likely determined by how many dispatched actions you have below
	// in the created() method
	LOADING_SEGMENTS: 2,
	VERSION: '20210730',
	API_BASE_PATH: `${process.env.NODE_ENV === 'development' ? 'https://api-rheiner.apps.new.de/wp-json' : 'https://api-rheiner.apps.new.de/wp-json'}/wp/v2/`,
	API_SEARCH_PATH: `${process.env.NODE_ENV === 'development' ? 'https://api-rheiner.apps.new.de/wp-json' : 'https://api-rheiner.apps.new.de/wp-json'}/swp_api/search`,
	API_FILTER_PATH: `${process.env.NODE_ENV === 'development' ? 'https://api-rheiner.apps.new.de/wp-json' : 'https://api-rheiner.apps.new.de/wp-json'}/facetwp/v1/`,
	API_FORMS_PATH: `${process.env.NODE_ENV === 'development' ? 'https://api-rheiner.apps.new.de/wp-json' : 'https://api-rheiner.apps.new.de/wp-json'}/contact-form-7/v1/contact-forms`,
};
