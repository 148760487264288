<template>
	<div id="app" class="o-app">
		<app-header :is-loading="isLoading"/>

		<transition name="loader-animation">
			<progress-bar :show-loader="showLoader" :loader-style="loaderStyle"/>
		</transition>

		<transition name="route" mode="out-in">
			<!--<router-view v-if="!isLoading && userUid" :key="$route.fullPath"></router-view> -->
      <div v-if="!isLoading && userUid">

        <form class="layout_form cr_form cr_font" action="https://eu.cleverreach.com/f/141596-171802/wcs/" method="post" target="_blank">
          <div class="cr_body cr_page cr_font formbox">
          <div class="non_sortable" style="text-align:left;"></div>
          <div class="editable_content" style="text-align:left;">
          <div id="3981779" rel="mce_text" class="cr_ipe_item ui-sortable">
          <div class="mce_text"><p>Hallo liebe Ruheständler:innen.</p><p><b>Der digitale Rheiner wird in Kürze eingestellt!</b></p><p>Wir wollen Euch allerdings weiterhin auf dem Laufenden halten und bieten an dieser Stelle die Möglichkeit den <b>Newsletter für Ruheständler:innen</b> zu abonnieren.</p><p>Zukünftig werden wir Euch auf diesem Wege regelmäßig über <b>Neuigkeiten der NEW-Gruppe</b> informieren.</p><p>Bei Interesse bitte einfach Eure private E-Mailadresse eintragen, absenden und immer auf dem neusesten Stand sein.</p><p>Natürlich könnt Ihr Euch auch jederzeit wieder abmelden.</p></div>
        </div><div id="3981780" rel="mce_text" class="cr_ipe_item ui-sortable">
          <div class="mce_text"><p><a href="https://datenschutz.new.de" mce_href="https://datenschutz.new.de" title="NEW Datenschutz" target="_blank">Datenschutzbestimmungen</a> der NEW Gruppe zum nachlesen.</p></div>
        </div><div id="3981774" rel="hr" class="cr_ipe_item ui-sortable cr_noedit" style="">
          <hr class="cr_hr" />
        </div><div id="3981713" rel="email" class="cr_form-component cr_form-component--email cr_ipe_item ui-sortable musthave" style="margin-bottom:px;">
          <div class="cr_form-inputgroup cr_form-inputgroup--typeemail">
            <label for="text3981713">E-Mail*</label>
            <input class="cr_form-input" type="email" id="text3981713" name="email" value="" placeholder="name@example.com" style="width:100%;">
          </div>
        </div><div id="3981715" rel="button" class="cr_form-component cr_form-component--submit cr_ipe_item ui-sortable  submit_container">
          <button type="submit" class="cr_form-block cr_button">Anmelden</button>
        </div>

        </div>

         <noscript><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript>
        </div>


          <div class="badge" style="text-align:center; border:none !Important; background:transparent !Important;"><a href="https://www.cleverreach.de/?utm_source=system&utm_medium=form&utm_campaign=c141596" target="_blank"><img src="https://d388us03v35p3m.cloudfront.net/cr3_images/badget1.png" border="0" /></a></div>

        </form>
      </div>
			<div v-else-if="userRestrictionMsg" class="c-message">
				<div class="c-message__inner">
					<h1 v-if="userRestrictionMsg.restricted_content_headline">{{ userRestrictionMsg.restricted_content_headline }}</h1>
					<p v-if="userRestrictionMsg.restricted_content_text" v-html="userRestrictionMsg.restricted_content_text"></p>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import SETTINGS from "./settings";
import {mapGetters, mapActions} from "vuex";
import Header from "./components/partials/Header.vue";
import ProgressBar from "./components/partials/ProgressBar.vue";

export default {
	name: 'app',

	data() {
		return {
			showLoader: true
		}
	},

	mounted() {
		this.checkUpdate()
	},

	computed: {
		...mapGetters([
			'isLoading',
			'loadingProgress',
			'userUid',
			'userRestrictionMsg'
		]),

		loaderStyle() {
			return `width: ${this.loadingProgress}%;`;
		},
	},

	methods: {
		...mapActions(['openMenu', 'setCurrentMenu', 'getUserPostData', 'setUserID']),

		checkUpdate() {
			const localStorageEntry = parseInt(localStorage.getItem('app_version'))
			const currentVersion = parseInt(SETTINGS.VERSION);

			if (!localStorageEntry || localStorageEntry && localStorageEntry < currentVersion) {
				localStorage.clear();
				localStorage.setItem('app_version', currentVersion);
				this.$router.go();
			}
		},
	},

	watch: {
		isLoading(val) {
			if (val === false) {
				let self = this;
				setTimeout(function () {
					self.showLoader = false;
				}, 1000);
			}
		}
	},

	components: {
		appHeader: Header,
		ProgressBar
	}
}
</script>

<style lang="scss" src="@/styles/main.scss"></style>


<style lang="scss">
.route-enter-active,
.route-leave-active {
	@include anim();
	transition-duration: 0.4s;
	transition-timing-function: ease;
	transition-property: opacity, transform;
}

.route-enter,
.route-leave-to {
	opacity: 0;
	transform: translateY(5vh);
}

.route-enter-to,
.route-leave {
	opacity: 1;
	transform: translateY(0);
}
</style>

<style lang="css">
* {box-sizing: border-box;}
.cr-mail-responsive, .cr-mail-responsive * {box-sizing: unset;}
.cr_site{margin:0;padding:75px 0 0 0;text-align:center;background-color:#eeeeee;}
.cr_font{font-size: 14px;font-family: Arial;}
.cr_page{width: 100% !important;max-width:640px}
.cr_body{box-shadow: 0px 0px 14px 0px rgba(51,51,51,0.4);border-radius:8px;line-height:150%;font-family:Helvetica;font-size:12px;color:#333333;}
.cr_body h2, .cr_header h2{font-size:22px;line-height:28px;margin:0 0 10px 0;}
.cr_body h1, .cr_header h2{font-size:28px;margin-bottom:15px;padding:0;margin-top:0;}
.wrapper, .cr_page{margin:0 auto 10px auto;text-align:left;}
.cr_header{text-align:center;background: transparent !Important;}
.cr_header img {max-width: 100%;}
.cr_body label, .cr_body .label{float:none;clear:both;display:inline-block;width:auto;margin-top:8px;text-align:left;font-weight:bold;position:relative;}
.cr_body .no-label{font-weight: normal;}
.cr_body #editable_content{padding:20px;}
.editable-content {padding:20px}
.cr_button{display:inline-block;font-family:'Helvetica', Arial, sans-serif;width:auto;white-space:nowrap;height:32px;margin:5px 0;padding:0 22px;text-decoration:none;text-align:center;font-weight:bold;font-style:normal;font-size:15px;line-height:32px;cursor:pointer;border:0;-moz-border-radius:4px;border-radius:4px;-webkit-border-radius:4px;vertical-align:top;}
.cr_button{background-color:#333;color:#ffffff;}
.cr_button:hover,.cr_button-small:hover{opacity:0.7;filter:alpha(opacity=70);}
.powered{padding:20px 0;width:560px;margin:0 auto;}
.cr_ipe_item label{line-height:150%;font-size:14px;}
.cr_ipe_item textarea{background: none repeat scroll 0 0 #ffffff;border-radius:3px;border: 1px solid #ccc;font-family: Helvetica;font-size: 16px;}
.cr_ipe_item input{background: none repeat scroll 0 0 #ffffff;border-radius:3px;border: 1px solid #ccc;padding: 12px;font-family: Helvetica;font-size: 16px;}
.cr_ipe_item select{background: none repeat scroll 0 0 #ffffff;border-radius:3px;border: 1px solid #ccc;padding: 12px !Important;display: block;margin: 0;padding: 5px;width: 100%;font-family: Helvetica;font-size: 16px;}
.cr_ipe_item input.cr_ipe_radio, input.cr_ipe_checkbox{-moz-binding: none;-moz-box-sizing: border-box;background-color: -moz-field !important;border: 2px inset ThreeDFace !important;color: -moz-fieldtext !important;cursor: default;height: 13px;padding: 0 !important;width: 13px;}
.cr_ipe_item input.cr_ipe_radio{-moz-appearance: radio;border-radius: 100% 100% 100% 100% !important;margin: 3px 3px 0 5px;}
.cr_ipe_item{margin: 0px 10px; padding: 0px 10px;}
.submit_container{text-align:center}
.cr_ipe_item.inactive{display:none;}
.imprint{font-size:0.8em;}
.cr_captcha{padding-left:130px;}
.cr_error{font-size:1.1em;padding:10px;}
.clever_form_error{background-color:#f99; color:#000; border:1px solid #f22 !important}
.clever_form_note {margin:26px 0 0 3px;position:absolute;display:inline; padding: 2px 4px; font-weight:bold;background-color:#f2ecb5; color:#000; font-size:12px !important;  }
.cr_form-flex{display: flex;}
.cr_form-flex>.cr_form-inputgroup{flex-grow:1;margin-right: 5px;}
.cr_form-flex>.cr_form-inputgroup:last-child{margin-right: 0px;}
.cr_form-flex input{width:100%;}

.cr_site {background-color:#eee;}
.cr_header {color:#000000;}
.cr_body {background-color:#ffffff;font-size:12px;color:#000000;}
.cr_hr {background-color:#ccc;}
.cr_site a {color:#0084ff;}
.imprint{color:#000;}


.cr_site {background-color:#eee;}
.cr_header {color:#B40055;}
.cr_body {background-color:#ffffff;font-size:12px;color:#000000;}
.cr_hr {background-color:#B40055;color:#B40055;}
.cr_site a {color:#B40055;}
.imprint {color:#000;}
.cr_page {width:auto;max-width:640px;}
.cr_font {font-family:Arial;}
.cr_button {background-color:#B40055;}



</style>
