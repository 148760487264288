import _isNull from "lodash.isnull";
import _isNumber from "lodash.isnumber";
import axios from "axios";
import router from "../router"
import SETTINGS from "../settings";


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if(urlParams.get('id_token')) {
    localStorage.new_openid_token = urlParams.get('id_token');
}

const instance = axios.create({
    baseURL: SETTINGS.API_BASE_PATH,
    headers: {
        'apikey': 'e7b74db8c11a17209c5a920d2d57e829',
        'secret': '65e1f06469661f3c9c6a1cf9f6b32092',
        'token': (localStorage.new_openid_token ? localStorage.new_openid_token : urlParams.get('id_token'))
    }
});

const feedbackInstance = axios.create({
    baseURL: SETTINGS.API_FORMS_PATH,
    headers: {
        'apikey': 'e7b74db8c11a17209c5a920d2d57e829',
        'secret': '65e1f06469661f3c9c6a1cf9f6b32092',
        'token': (localStorage.new_openid_token ? localStorage.new_openid_token : urlParams.get('id_token'))
    }
})

export default {
    getPages(cb) {
        instance
            .get("pages")
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                // eslint-disable-next-line no-undef
                if(e.response.status === 401 && loginNew.isSignedIn()) {
                    // eslint-disable-next-line no-empty
                    if(e.response.data.message === 'token_expired') {
                    } else {
                        router.push({ path: '/error'});
                    }
                }
            });
    },

    async getPage(id, cb) {
        if (_isNull(id) || !_isNumber(id)) return false;
        await instance
            .get(`pages/${id}`)
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e);
            });
    },

    getPosts(cb) {
        instance
            .get('posts?per_page=100')
            // TODO: When more than 100 articles, the rest will be ignored
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e);
            });
    },

    getPost(slug, oid, cb) {
        instance
            .get(`posts/?slug=${slug}&user=${oid}`)
            .then(response => {
                const postObj = response.data[0];

                cb(postObj);
            })
            .catch(e => {
                cb(e);
            })
    },

    async getPostPreview(id, cb) {
        if (_isNull(id) || !_isNumber(id)) {
            return false;
        }
        await instance
            .get(`posts/preview/${id}`)
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e);
            })
    },

    getArchive(tag, cb) {
        instance
            .get(`posts/tag/${tag}`)
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e)
            })
    },

    getArchiveName(tag, cb) {
        instance
            .get(`posts/tagname/${tag}`)
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e)
            })
    },

    getMenu(cb) {
        instance
            .get('menu')
            .then(response => {
                cb(response.data);
            })
            .catch(e => {
                cb(e)
            })
    },

    getUser(oid, form_data, cb) {
        instance
            .post(`account/data/${oid}`, form_data)
            .then((response => {
                cb(response.data)
            }))
            .catch(e => {
                cb(e)
            })
    },

    getAccPosts(oid, cb) {
        axios.all([
            instance.get(`account/likes/${oid}`),
            instance.get(`account/bookmarks/${oid}`),
            instance.get(`account/history/${oid}`),
        ])
            .then(axios.spread((likesRes, bookmarkRes, historyRes) => {
                cb({
                    'bookmarks': {
                        'singleRouteName': 'bookmark',
                        'title': 'Gespeichert',
                        'data': bookmarkRes.data
                    },
                    'likes': {
                        'singleRouteName': 'like',
                        'title': 'Gefällt',
                        'data': likesRes.data
                    },
                    'history': {
                        'singleRouteName': 'history',
                        'title': 'Verlauf',
                        'data': historyRes.data
                    },
                })
            }))
            .catch(e => {
                cb(e)
            })
    },

    toggleUserBookmark(oid, id, cb) {
        instance.post(`account/bookmark/${oid}/${id}`)
            .then(() => {
                instance.get(`account/bookmarks/${oid}`)
                    .then(res => {
                        cb({
                            'singleRouteName': 'bookmark',
                            'title': 'Gespeichert',
                            'data': res.data
                        })
                    })
                    .catch(e => {
                        cb(e)
                    })
            })
            .catch(e => {
                cb(e)
            })
    },

    toggleUserLike(oid, id, cb) {
        instance.post(`account/like/${oid}/${id}`)
            .then(() => {
                instance.get(`account/likes/${oid}`)
                    .then(res => {
                        cb({
                            'singleRouteName': 'like',
                            'title': 'Gefällt',
                            'data': res.data
                        })
                    })
                    .catch(e => {
                        cb(e)
                    })
            })
            .catch(e => {
                cb(e)
            })
    },

    updateUserHistory(oid, id, cb) {
        instance.post(`account/history/${oid}/${id}`)
            .then(() => {
                instance.get(`account/history/${oid}`)
                    .then(res => {
                        cb({
                            'singleRouteName': 'history',
                            'title': 'Verlauf',
                            'data': res.data.reverse()
                        })
                    })
                    .catch(e => {
                        cb(e)
                    })
            })
            .catch(e => {
                cb(e)
            })
    },

    removeUserHistoryItem(oid, row, cb) {
        instance.delete(`account/history/${oid}/${row}`)
            .then(res => {
                cb({
                    'singleRouteName': 'history',
                    'title': 'Verlauf',
                    'data': res.data.reverse()
                })
            })
            .catch(e => {
                cb(e)
            })
    },

    submitFeedback(id, body, cb) {
        feedbackInstance.post(`/${id}/feedback`, body)
            .then(res => {
                cb(res.data)
            })
            .catch(e => {
                cb(e)
            })
    },

    submitContactform(id, body, cb) {
        feedbackInstance.post(`/${id}`, body)
            .then(res => {
                cb(res.data)
            })
            .catch(e => {
                cb(e)
            })
    },

	getSiteMessage(cb) {
		instance.get('statics')
			.then(r => cb(r.data))
			.catch(e => cb(e))
	}
};
