<template>
    <div class="c-loader" v-if="showLoader">
        <div
                class="c-loader__bar"
                role="progressbar"
                :style="loaderStyle"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
        ></div>
    </div>
</template>

<script>
    export default {
        props: {
          showLoader: Boolean,
          loaderStyle: String
        }
    };
</script>

<style lang="scss" scoped>
    .c-loader {
        position: fixed;
        top: 0;
        width: 100%;
        height: 3px;
        z-index: 1000000;
    }

    .c-loader__bar {
        background: $color-primary;
        height: 3px;
        transition: all 0.25s;
        transition-timing-function: $global-timing-func;
    }

    .loader-animation-leave-active {
        transition: delay 1s;
    }
</style>
