import Vue from 'vue'
import Vuex from 'vuex'
import createPersist from 'vuex-persist'
import hub from './modules/hub'
import user from './modules/user'
import post from './modules/post'
import page from './modules/page'
import menu from './modules/menu'
import feedback from './modules/feedback'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new createPersist({
	key: 'RHEINER',
	storage: window.localStorage,
	reducer: state => ({hub: state.hub, page: state.page, menu: state.menu})
})

export default new Vuex.Store({
	modules: {
		hub,
		menu,
		feedback,
		user,
		post,
		page
	},
	strict: debug,
	plugins: [vuexLocal.plugin]
})
