<!--suppress XmlDuplicatedId -->
<template>
	<nav class="c-nav" v-if="menu && userUid && 1==2">
		<button class="c-nav__btn"
						:class="[ { 'c-nav__btn--active' : activeNav(item.type) }, `c-nav__btn--${item.text.toLowerCase()}` ]"
						v-for="(item, index) in menu.menu" :key="index" v-touch:tap="toggleMenu(item.type)"
						@keyup.13="hoverMenu(item.type)">
			<!-- TODO: Move Menu here for a11y-->
			<inline-svg v-if="item.icon" class="c-nav__icon" :ref="`nav-icon-${item.icon}`"
									:src="require(`@/assets/icons/${item.icon}.svg`)"></inline-svg>
			<span class="c-nav__title">{{ item.text }}</span>
		</button>
		<div class="c-nav__btn c-nav__btn--auth" id="loginnew-dropdown"></div>
		<transition name="moveup">
			<button class="c-nav__btn c-nav__btn--close" v-if="searchMenuIsOpen" @click="closeMenu()" key="closeSearch">
				<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" v-bind:svg-inline="''" v-bind:class="'c-nav__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l9 9.75-9 9.75M19 1l-9 9.75 9 9.75"/></svg>
				<span class="c-nav__title">Schliessen</span>
			</button>
		</transition>
	</nav>
	<nav v-else class="c-nav">
		<div class="c-nav__btn c-nav__btn--auth" id="loginnew-dropdown"></div>
	</nav>
</template>

<script>
import {disablePageScroll, enablePageScroll} from 'scroll-lock';

import InlineSvg from 'vue-inline-svg'
import {mapActions, mapGetters} from 'vuex'

export default {
	name: 'Nav',

	watch: {
		'$route': function (route) {
			if (route.path === '/') {
				this.setCurrentMenu('home')
			}
		},

		'menuIsOpen': function (open) {
			const htmlEl = document.querySelector('html')

			if (open) {
				if (this.$mq === 'large' && this.$mq === 'full') disablePageScroll(document.querySelector('.c-menu'))
				this.scrollPos = window.pageYOffset
				if (this.$mq === 'small' || this.$mq === 'medium') {
					document.body.style.top = `${this.scrollPos * -1}px`
				}
				htmlEl.classList.add(`menu-is-open`)
			} else {
				if (this.$mq === 'large' && this.$mq === 'full') enablePageScroll(document.querySelector('.c-menu'))
				htmlEl.classList.remove('menu-is-open')

				if (this.$mq === 'small' || this.$mq === 'medium') {
					document.body.style.top = ``
					window.scrollTo(0, this.scrollPos)
				}
			}
		},

		'currentMenu': function (item, lastItem) {
			const htmlEl = document.querySelector('html')

			if (item !== 'home') this.openMenu(true)

			if (htmlEl.classList.contains(`menu-${lastItem}`)) htmlEl.classList.remove(`menu-${lastItem}`)

			htmlEl.classList.add(`menu-${item}`)
		}
	},

	data() {
		return {
			scrollPos: 0
		}
	},

	methods: {
		...mapActions({
			openMenu: 'openMenu',
			setCurrentMenu: 'setCurrentMenu'
		}),

		loginUser() {
			// eslint-disable-next-line no-undef
			loginNew.auth()
		},

		logoutUser() {
			// eslint-disable-next-line no-undef
			loginNew.logout()
		},

		closeMenu() {
			if (this.$route.path === '/') this.setCurrentMenu('home');
			this.openMenu(false)
		},

		hoverMenu(type) {
			this.setCurrentMenu(type)

			if (type === 'home') {
				this.openMenu(false);
			} else {
				this.openMenu(true)
			}
		},

		toggleMenu(type) {
			return (event) => {
				event.target.blur()
				this.setCurrentMenu(type)

				if (type === 'home') {
					this.openMenu(false)

					if (this.$route.path !== '/') this.$router.push('/')
				} else {
					this.openMenu(!this.menuIsOpen)
				}
			}
		},

		activeNav(type) {
			switch (type) {
				case 'home':
					if (this.$route.path === '/' && type === this.currentMenu) return true;
					break;

				case 'search':
				case 'menu':
				case 'account':
					if (type === this.currentMenu && this.menuIsOpen) return true;
					break;
			}
		}
	},

	computed: {
		...mapGetters({
			menuIsOpen: 'menuIsOpen',
			menu: 'menu',
			currentMenu: 'currentMenu',
			userUid: 'userUid'
		}),

		searchMenuIsOpen() {
			return window.matchMedia('(min-width: 1024px)').matches && this.menuIsOpen && this.currentMenu === 'search'
		},

		isLoggedIn() {
			// eslint-disable-next-line no-undef
			return loginNew.isSignedIn()
		}
	},

	components: {
		InlineSvg
	}
}
</script>

<style lang="scss" src="@/styles/components/_nav.scss"></style>
