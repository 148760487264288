import * as types from '../mutation-types'

const state = {
    step: 0
}

const getters = {
    currentStep: state => state.step
}

const actions = {
    incrementFeedbackStep({ commit }) {
        commit(types.INCREMENT_FEEDBACK_STEP)
    },
    decrementFeedbackStep({ commit }) {
        commit(types.DECREMENT_FEEDBACK_STEP)
    },
    updateFeedbackStep({ commit }, val) {
        commit(types.UPDATE_FEEDBACK_STEP, val)
    }
}

const mutations = {
    [types.INCREMENT_FEEDBACK_STEP](state) {
        state.step += 1
    },
    [types.DECREMENT_FEEDBACK_STEP](state) {
        state.step -= 1
    },
    [types.UPDATE_FEEDBACK_STEP](state, val) {
        state.step = val
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}